import axios from 'axios';
import publicIp from 'public-ip';
import { v4 as uuidv4 } from 'uuid';

const ContactSvc = {
    getIP: async () => {
        try {
            const ip = await publicIp.v4();

            if(ip)
            {
                console.log(`Hello friend  - ${ip}! We use this IP so you can chat with us on Slack (bottom left corner). Cool, huh?`);
            }

            return ip;
        } catch(e) {
            return uuidv4();
        }        
    },
    sendEmail: async (reason, name, email, subject, message, token) => {
        await axios.post(
                'https://api.r2c.io/sendmail',
        {
            reason, name, email, subject, message, token
        });
    }
};

export default ContactSvc;