/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Suspense } from "react";
import ReactGA from "react-ga";
import CookieConsent from 'react-cookie-consent';

import {
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavItem,
  NavLink,
  Footer,
  Container,
  NavbarToggler,
  Collapse,
  MDBRow,
  MDBCol,
  MDBIcon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  MDBContainer,
  MDBBtn,
} from "mdbreact";
import { Route, withRouter, Link } from "react-router-dom";
import { ReactSlackChat } from "react-slack-chat";
import 'react-toastify/dist/ReactToastify.css';

import "./App.scss";
import ContactSvc from "./services/ContactSvc";
import ContactModal from "./components/ContactModal";
import r2c_logo from "./assets/img/r2c_logo.webp";
import r2c_logo_png from "./assets/img/png/r2c_logo.png";
import r2cloud_logo from "./assets/img/r2c_logo.png";
import r2cloud_logo_png from "./assets/img/png/r2cloud_logo.png";
import loading_spinner from "./assets/img/R2Logo.png";
import ScrollToTop from "./components/ScrollToTop";
import r2Logo from "./assets/img/png/r2c_logo_blue.png"
import AccessabilityModal from "./components/AccessabilityModal";
import { AppProvider } from "./store/appContext";

const Home = lazyWithPreload(() => import("./pages/home"));
const SmartBuildingsAndIot = lazyWithPreload(() => import("./pages/smartBuildingsAndIot"));
const Services = lazyWithPreload(() => import("./components/Services"));
const Approach = lazyWithPreload(() => import("./components/ApproachPage"));
const AboutUs = lazyWithPreload(() => import("./components/AboutUs"));
const Portfolio = lazyWithPreload(() => import("./pages/portfolio"));
const CaseStudy = lazyWithPreload(() => import("./pages/caseStudy"));
const Startup = lazyWithPreload(() => import("./components/startup/Startup"));
const ProductDevelopment = lazyWithPreload(() =>
  import("./components/ProductDevelopment")
);
const Devops = lazyWithPreload(() => import("./pages/devops"));
const Blockchain = lazyWithPreload(() => import("./pages/blockchain/index"));
// const Fitness = lazyWithPreload(() => import("./pages/fitness/index"));
const Login = lazyWithPreload(() => import("./pages/login/index"));
const DataServices = lazyWithPreload(() => import("./components/DataServices"));
const PrivacyPolicy = lazyWithPreload(() => import("./components/PrivacyPolicy"));
const CookiePolicy = lazyWithPreload(() => import("./components/CookiePolicy"));
const Terms = lazyWithPreload(() => import("./components/Terms"));
const Contact = lazyWithPreload(() => import("./components/Contact"));
const CaseStudyBuilder = lazyWithPreload(() => import("./pages/caseStudyBuilder"));
const AdminDashboard = lazyWithPreload(() => import("./pages//admin/AdminDashboard"));
const ProjectBuilder = lazyWithPreload(() => import("./pages//admin/project"));

function lazyWithPreload(factory) {
  const Component = React.lazy(factory);
  Component.preload = factory;
  return Component;
}

const routes = [
  {
    id: 1,
    url: "/",
    text: "",
    component: Home
  },
  {
    id: 2,
    url: "/startup",
    text: "Startup",
    component: Startup
  },
  {
    id: 3,
    url: "/services",
    text: "Services",
    component: Services
  },
  {
    id: 4,
    url: "/approach",
    text: "Approach",
    component: Approach
  },
  {
    id: 5,
    url: "/about-us",
    text: "About Us",
    component: AboutUs
  },
  {
    id: 6,
    url: "/product-development",
    text: "Product Development",
    component: ProductDevelopment
  },
  {
    id: 7,
    url: "/data-services",
    text: "Data Services",
    component: DataServices
  },
  {
    id: 8,
    url: "/devops",
    text: "DevOps",
    component: Devops
  },
  {
    id: 13,
    url: "/blockchain",
    text: "Blockchain",
    component: Blockchain
  },
  // {
  //   id: 14,
  //   url: "/fitness",
  //   text: "Fitness",
  //   component: Fitness
  // },
  {
    id: 9,
    url: '/smart-buildings-and-iot',
    text: "Smart buildings & IoT",
    component: SmartBuildingsAndIot
  },
  {
    id: 19,
    url: "/case-study",
    text: "CaseStudy",
    component: CaseStudy
  },
  // {
  //   id: 20,
  //   url: "/case-study/:id",
  //   text: "CaseStudy",
  //   component: Fitness
  // },
  {
    id: 15,
    url: "/portfolio",
    text: "Portfolio",
    component: Portfolio
  },
  {
    id: 15,
    url: "/portfolio/:project",
    text: "Portfolio",
    component: Portfolio
  }, {
    id: 16,
    url: '/privacy-policy',
    text: "Privacy Policy",
    component: PrivacyPolicy
  },
  {
    id: 17,
    url: '/cookie-policy',
    text: "Cookie Policy",
    component: CookiePolicy
  },
  {
    id: 18,
    url: '/terms',
    text: "Terms of use",
    component: Terms
  },
  {
    id: 11,
    url: "/admin/login",
    text: "Login",
    component: Login
  },
  {
    id: 21,
    url: "/admin/dashboard",
    text: "Dashboard",
    component: AdminDashboard,
  },
  {
    id: 12,
    url: '/admin/case-study/create',
    text: "Case study editor",
    component: CaseStudyBuilder
  },
  {
    id: 10,
    url: '/admin/case-study/:id/edit',
    text: "Case study editor",
    component: CaseStudyBuilder
  },
  {
    id: 22,
    url: '/admin/project/create',
    text: "Project editor",
    component: ProjectBuilder,
  },
  {
    id: 24,
    url: '/admin/project/:id/edit',
    text: "Project editor",
    component: ProjectBuilder,
  },
];

const navRoutes = [
  { id: 1, url: "/", text: "", component: Home },
  {
    id: 3,
    url: "/services",
    text: "Services",
    component: Services,
    routes: [
      {
        id: 4,
        url: "/services",
        text: "IT Services"
      },
      {
        id: 5,
        url: "/product-development",
        text: "Product Development"
      },
      {
        id: 6,
        url: "/data-services",
        text: "Data Services"
      },
      {
        id: 7,
        url: "/devops",
        text: "DevOps"
      },
      {
        id: 13,
        url: "/blockchain",
        text: "Blockchain"
      },
      // {
      //   id: 14,
      //   url: "/fitness",
      //   text: "Fitness"
      // },
      {
        id: 12,
        url: "/smart-buildings-and-iot",
        text: "Smart buildings & IoT"
      }
    ]
  },
  {
    id: 8,
    url: "/portfolio",
    text: "Portfolio",
    component: Portfolio
  },
  // {
  //   id: 21,
  //   url: "/case-study",
  //   text: "CaseStudy",
  //   component: CaseStudy
  // },
  {
    id: 9,
    external: true,
    url: "https://blog.r2c.io",
    text: "Blog",
  },
  {
    id: 10,
    url: "/about-us",
    text: "About Us",
    component: AboutUs
  },
  {
    id: 2,
    url: "/startup",
    text: "Startup",
    component: Startup
  },
  {
    id: 11,
    url: "#contact-container",
    text: "Contact",
    component: Contact
  },
  // {
  //   id: 20,
  //   url: "/admin/login",
  //   text: "Login",
  //   component: Login
  // }
];

function debounce(fn, ms) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.call(...args, null), ms);
  };
}

class App extends Component {
  state = {
    collapseID: "",
    collapse1: false,
    userIP: "",
    name: "",
    email: "",
    subject: "",
    message: "",
    token: null,
    showScrollToTop: false,
    modal: false,
    accesabilityModal: false,
    cookieBanner: false,
    showNumbers: false,
    updateAccesability: false
  };

  constructor() {
    super();
    this.slackChatRef = React.createRef();
  }

  async componentDidMount() {
    const gaId =
      process.env.REACT_APP_COMPANY &&
        process.env.REACT_APP_COMPANY === "r2cloud"
        ? "UA-171247536-1"
        : "UA-158960432-1";
    ReactGA.initialize(gaId);
    this.trackGAPageViews();
    const userIP = await ContactSvc.getIP();
    this.setState({ userIP });
    window.addEventListener("scroll", debounce(this.handleScroll, 100));
    document.querySelector("h2[class^=ReactSlackChat__subText]").innerText =
      "Ask us anything...and we will do our best to answer";
  }

  componentWillMount() {
    this.unlistenToUrlChanges = this.props.history.listen(location => {
      this.trackGAPageViews();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.unlistenToUrlChanges();
  }

  trackGAPageViews = () => {
    const { location } = this.props;
    ReactGA.pageview(location.pathname + location.search);
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  };

  isActive(url, path) {
    if (path !== "/") return url === path ? "active" : "";
  }

  getLogo(path) {
    if (
      process.env.REACT_APP_COMPANY &&
      process.env.REACT_APP_COMPANY === "r2cloud"
    )
      return { img: r2cloud_logo, altImg: r2cloud_logo_png };
    else return { img: r2c_logo, altImg: r2c_logo_png };
  }

  handleScroll = e => {
    // console.log(window.scrollY);
    if (window.scrollY > 700) {
      this.setState({ showScrollToTop: true });
    } else {
      this.setState({ showScrollToTop: false });
    }
  };
  toggleContactModal = () => {
    this.setState(prevState => {
      return { modal: !prevState.modal };
    });
  };
  toggleCookieModal = () => {
    this.setState(prevState => {
      return { cookieBanner: !prevState.cookieBanner };
    });
  };
  toggleAccessModal = () => {

    this.setState(prevState => {
      return { accesabilityModal: !prevState.accesabilityModal };
    });
  };
  toggleAccessModalSuccess = () => {

    this.setState(prevState => {
      return { accesabilityModal: !prevState.accesabilityModal };
    });
    this.setState({ updateAccesability: true })
    this.scrollToContactForm()

  };

  toggleSlackChat = e => {
    let el = this.slackChatRef;
    el.current.openChatBox(e);
    if (this.state.modal) {
      this.toggleContactModal();
    }
  };

  toggleAppointmentWindow = () => {
    let el = document.getElementsByClassName("calendly-badge-content");
    el[0].click();
    if (this.state.modal) {
      this.toggleContactModal();
    }
  };

  scrollToContactForm = () => {
    window.scrollTo({
      top: document.getElementById("contact-container").offsetTop - 90,
      behavior: "smooth"
    });
    if (this.state.modal) {
      this.toggleContactModal();
    }
  };

  showPhoneNumberToggle = () => {
    this.setState(prevState => {
      return { showNumbers: !prevState.showNumbers };
    });
  };

  render() {
    const { history } = this.props;
    const { pathname } = this.props.location;
    const { showScrollToTop } = this.state;
    return (
      <div
        className="App"
        style={{ overflowX: "hidden", paddingTop: pathname !== '/about-us' ? '30px' : 0 }}
        onScroll={e => console.log("scroll")}
      >
        <Navbar
          color="transparent"
          dark
          expand="md"
        >
          <NavbarToggler
            left
            role='button'
            aria-expanded='false'
            aria-label='Menu'
            onClick={() => this.toggleSingleCollapse("basicCollapse")}
          />
          <NavbarBrand>
            <NavLink to="/">
              <picture>
                <source srcSet={this.getLogo(pathname).img} type="image" />
                <source srcSet={this.getLogo(pathname).altImg} type="image" />

                <img
                  className={
                    process.env.REACT_APP_COMPANY &&
                      process.env.REACT_APP_COMPANY === "r2cloud"
                      ? "logo_r2cloud"
                      : "logo"
                  }
                  src={this.getLogo(pathname).altImg}
                  alt="R2C Logo"
                />
              </picture>
            </NavLink>
          </NavbarBrand>
          <Collapse id="basicCollapse" isOpen={this.state.basicCollapse}>
            <ul className="ham-menu-list">
              {navRoutes.map(route => {
                if (route.text === "Blog") {
                  return (<a key={route.url} className="nav-link mobile-navlink" href="/" onClick={() => {
                    window.open(route.url, '_blank');
                  }}>{route.text}</a>);
                }
                else if (route.text === "Services") {
                  return (
                    <div key={`nav${route.id}`}>
                      <NavItem
                        onClick={() =>
                          this.toggleSingleCollapse("navItemCollapse")
                        }
                        className="services-nav-item d-flex justify-content-between align-items-center"
                      >
                        <span className="nav-link span-link">{route.text}</span>
                        <MDBIcon
                          icon={
                            this.state.navItemCollapse
                              ? "caret-up"
                              : "caret-down"
                          }
                          style={{ color: "#ffffff", marginRight: " 10px" }}
                        />
                      </NavItem>
                      <Collapse
                        key={`collapsenav${route.id}`}
                        id="navItemCollapse"
                        isOpen={this.state.navItemCollapse}
                      >
                        {route.routes.map(route => (
                          <NavItem
                            className="nested-nav-item"
                            onClick={() =>
                              this.toggleSingleCollapse("basicCollapse")
                            }
                            key={route.id}
                          >
                            <NavLink to={route.url} aria-label={route.text}>{route.text}</NavLink>
                          </NavItem>
                        ))}
                      </Collapse>
                    </div>
                  );
                } else if (route.text === "Contact") {
                  return (
                    <NavItem
                      className={`d-flex items-center ${this.isActive(route.url, pathname)}`}
                      key={`navitem${route.id}`}
                      onClick={() => {
                        this.scrollToContactForm();
                        this.toggleSingleCollapse("basicCollapse");
                      }}
                    >
                      <NavLink to={route.url}>
                        <MDBBtn
                          size="sm"
                          className="m-0 bg-gradient text-white"
                        >

                          {route.text}
                        </MDBBtn>
                      </NavLink>
                    </NavItem>
                  );
                } else if (route.external) {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={`navitem2${route.id}`}
                      onClick={() => this.toggleSingleCollapse("basicCollapse")}
                    >
                      <NavLink to={{ pathname: route.url }} target="_blank">
                        {route.text}
                      </NavLink>
                    </NavItem>
                  );
                } else if (route.text) {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={`navitem2${route.id}`}
                      onClick={() => this.toggleSingleCollapse("basicCollapse")}
                    >
                      <NavLink to={route.url}>{route.text}</NavLink>
                    </NavItem>
                  );
                }
              })}
            </ul>
          </Collapse>
          <div className="spacer"></div>
          <Container className="nav-links-container">
            <NavbarNav right className="stylish-text">
              {navRoutes.map(route => {
                if (route.text === "Services") {
                  return (
                    <NavItem key={`cont${route.id}`}>
                      <Dropdown>
                        <DropdownToggle nav caret>
                          <div className="d-none d-md-inline">{route.text}</div>
                        </DropdownToggle>
                        <DropdownMenu right>
                          {route.routes.map(route => (
                            <DropdownItem
                              key={route.id}
                              onClick={() => history.push(route.url)}
                            >
                              {route.text}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                  );
                } else if (route.text === "Contact") {
                  return (
                    <NavItem
                      className={`d-flex items-center ml-2 ${this.isActive(route.url, pathname)}`}
                      key={route.id}
                      onClick={() => this.scrollToContactForm()}
                    >
                      <NavLink to={route.url} className="p-0">
                        <MDBBtn
                          size="sm"
                          className="m-0 bg-gradient text-white"
                        >

                          {route.text}
                        </MDBBtn>
                      </NavLink>
                    </NavItem>
                  );
                } else if (route.external) {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={`navitem2${route.id}`}
                      onClick={() => this.toggleSingleCollapse("basicCollapse")}
                    >
                      <NavLink to={{ pathname: route.url }} target="_blank">
                        {route.text}
                      </NavLink>
                    </NavItem>
                  );
                } else if (route.text) {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={route.id}
                    >
                      <NavLink to={route.url}>{route.text}</NavLink>
                    </NavItem>
                  );
                }
              })}
            </NavbarNav>
          </Container>
        </Navbar>
        {pathname !== '/about-us' && <div className="fwd_container">
          <NavLink to={`/about-us`}>
            <img src="img/fwdtgtherbanner.png" alt="banner_img" className="fwd_img" />
          </NavLink>
        </div>}
        <AppProvider>
          <ScrollToTop>
            <Suspense fallback={<div className="loading-spinner animated bounce infinite">
              <img src={loading_spinner} alt="Loading..." />
            </div>}>
              {routes.map(route => {
                return (
                  <Route
                    key={route.url}
                    exact
                    path={route.url}
                    render={props => (
                      <route.component
                        {...props}
                        toggleContactModal={this.toggleContactModal}
                        toggleSlackChat={this.toggleSlackChat}
                        toggleAppointmentWindow={this.toggleAppointmentWindow}
                        scrollToContactForm={this.scrollToContactForm}
                      />
                    )}
                  />
                );
              })}

            </Suspense>
          </ScrollToTop>
          <div
            className={`scrollToTop-btn ${showScrollToTop ? "show" : ""}`}
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              })
            }
          >
            <span></span>
          </div>
          {this.state.userIP ? (
            <div>
              <ReactSlackChat
                channels={[
                  {
                    name: "talk2us",
                    icon: "https://www.r2c.io/img/robot.png"
                  }
                ]}
                ref={this.slackChatRef}
                closeChatButton={true}
                helpText={"Slack Us"}
                subText={"Ask us anything...and we will do our best to answer"}
                botName={"r2b_" + this.state.userIP}
                singleUserMode={true}
                userImage={"https://robohash.org/" + this.state.userIP}
                apiToken="eG94Yi01ODUxNzY5NzU5NjktNzAwMzAwMDQwMjU3LTNEYVpTVGY4YTJzSVMwTGo2MThrUDRQcg"
              />
            </div>
          ) : (
            <span></span>
          )}
          {pathname !== "/admin/login" &&
            <Suspense fallback={<div className="loading-spinner animated bounce infinite">
              <img src={loading_spinner} alt="Loading..." />
            </div>}>
              <Contact disableMeta={this.props.location.pathname == '/contact'} updateAccesability={this.state.updateAccesability} />
            </Suspense>
          }
        </AppProvider>
        {pathname !== "/admin/login" &&
          <Footer className="font-small">
            <div className="footer-copyright">
              <Container>
                <MDBRow>
                  <MDBCol>
                    <h4>Company</h4>
                    <hr />
                    <div>
                      <p><Link to="/about-us">About Us</Link></p>
                      <p><Link to="/portfolio">Portfolio</Link></p>
                      <p><a target="_blank" rel="noopener noreferrer" href="https://blog.r2c.io">Blog</a></p>
                      <p><Link to="/startup">Startup</Link></p>
                    </div>
                  </MDBCol>
                  <MDBCol>
                    <h4>Services</h4>
                    <hr />
                    <div>
                      <p><Link to="/product-development">Product Development</Link></p>
                      <p><Link to="/services">IT Services</Link></p>
                      <p><Link to="/data-services">Data Services</Link></p>
                      <p><Link to="/devops">DevOps</Link></p>
                      <p><Link to="/blockchain">Blockchain</Link></p>
                      {/* <p><Link to="/fitness">Fitness</Link></p>*/}
                    </div>
                  </MDBCol>
                  <MDBCol>
                    <h4>Support</h4>
                    <hr />
                    <p><Link to="/privacy-policy">Privacy Policy</Link></p>
                    <p><Link to="/cookie-policy">Cookie Policy</Link></p>
                    <p><Link to="/terms">Terms of Use</Link></p>
                    <p onClick={() => this.setState({ accesabilityModal: true })}><span className="span-link">Accessibility</span></p>
                    <p onClick={() => this.setState({ cookieBanner: true })}><span className="span-link">Cookie Preferences</span></p>

                  </MDBCol>
                  <MDBCol>
                    <h4>Connect</h4>
                    <hr />
                    <span role="button" className="text-white pointer" onClick={() => this.scrollToContactForm()}>Contact us</span>
                    <br />
                    <br />
                    <Container className="d-flex" style={{ padding: 0 }}>
                      <script type="IN/FollowCompany" data-id="35647657" data-counter="bottom"></script>
                      {/* <a
                      className="social"
                      href={
                        process.env.REACT_APP_COMPANY &&
                          process.env.REACT_APP_COMPANY === "r2cloud"
                          ? "https://www.linkedin.com/company/r2cloud-solutions"
                          : "https://www.linkedin.com/company/r2consultingllc"
                      }
                      target="_blank"
                      title="LinkedIn"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon fab icon="linkedin" size="2x" />
                    </a> */}
                      <div className="fb-like mx-2" data-href="https://www.facebook.com/r2.consult" data-width="90" data-layout="button_count" data-action="like" data-size="small" data-share="false"></div>
                      <a target="_blank" href="https://www.youtube.com/channel/UCP8HtMheFDM0cqFY_XQEDGQ"
                        className="youtube social">
                      <MDBIcon fab icon="youtube" size="2x" style={{fontSize:"1.6em"}}/>
                      </a>
                      {/* <a
                      className="social"
                      href={
                        process.env.REACT_APP_COMPANY &&
                          process.env.REACT_APP_COMPANY === "r2cloud"
                          ? "https://twitter.com/r2cloud"
                          : "https://twitter.com/R2_Innov8"
                      }
                      target="_blank"
                      title="Twitter"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon fab icon="twitter" size="2x" />
                    </a> */}
                    </Container>
                    <div className="d-flex items-center">
                      <a target="_blank" rel="noopener noreferrer" href="https://www.expertise.com/co/denver/software-development" className="d-inline-block mt-4 mr-2" style={{ border: 0 }}>
                        <img style={{ width: "110px" }} width="110" height="130" src="https://res.cloudinary.com/expertise-com/image/upload/w_auto/remote_media/awards/co_denver_software-development_2023_inverse.svg" alt="Top Software Development Company in Denver" />
                      </a>
                      <img className="mt-4 mr-3" src="/img/ISO White Logo.png" width={120} height={120} alt="ISO" />
                      <iframe width="100" height="100" src="https://shareables.clutch.co/share/badges/1949220/1333?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch App Development Company Boulder 2023"></iframe>
                    </div>
                  </MDBCol>
                </MDBRow>
              </Container>
              <br />
              <MDBContainer fluid className="bg-black">
                <MDBRow>
                  <MDBCol sm="10"></MDBCol>
                  <MDBCol sm="2" className="text-right xs:text-center">
                    <img src={r2Logo} style={{ height: '4rem', maxWidth: '100%' }} className="py-2" alt="R2C" />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              {/* <MDBRow>
            <MDBCol className="text-center" style={{ color: '#555555' }}>
                  &copy; {new Date().getFullYear()}
                  <a href="/">
                    {" "}
                    {process.env.REACT_APP_COMPANY &&
                      process.env.REACT_APP_COMPANY === "r2cloud"
                      ? "R2Cloud Solutions Private Limited. All Rights Reserved. "
                      : "R2 Consulting. All Rights Reserved.  "}{" "}
                  </a>
                </MDBCol>
            </MDBRow> */}

            </div>
          </Footer>
        }
        <ContactModal
          modal={this.state.modal}
          showNumbers={this.state.showNumbers}
          toggleContactModal={this.toggleAccessModal}
          toggleSlackChat={this.toggleSlackChat}
          scrollToContactForm={this.scrollToContactForm}
          toggleAppointmentWindow={this.toggleAppointmentWindow}
          showPhoneNumberToggle={this.showPhoneNumberToggle}
        />
        <AccessabilityModal
          modal={this.state.accesabilityModal}
          toggleAccessModals={this.toggleAccessModal}
          toggleAccessModalSuccess={this.toggleAccessModalSuccess}
        />
        {this.state.cookieBanner &&
          <CookieConsent
            location="bottom"
            buttonText="I Accept"
            ariaAcceptLabel="I Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: "var(--blue-2)", zIndex: 9999 }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            onAccept={() => {
              this.toggleCookieModal()
            }}
          >
            R2 Consulting (www.r2c.io) uses cookies to offer you the most relevant information.

          </CookieConsent>
        }
      </div>
    );
  }
}

export default withRouter(App);
