// UserContext.js
import React, { createContext, useReducer, useContext } from 'react';

// Create the initial state
const initialState = {
  downloadWhitepaper: false,
};

// Create the context
const AppContext = createContext();

// Create a reducer function to handle state updates
const configReducer = (state, action) => {
  switch (action.type) {
    case 'SET_WHITEPAPER_CONFIG':
      return { ...state, downloadWhitepaper: action.payload };
    default:
      return state;
  }
};

// Create a custom provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(configReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to easily access the context
export const useStore = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useStore must be used within a AppProvider');
  }
  return context;
};
